<template>
  <div class="card">
    <div class="card-body pt-0">
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="
            nav nav-stretch nav-line-tabs nav-line-tabs-2x
            border-transparent
            fs-5
            fw-bolder
            flex-nowrap
          "
        >
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="'/animal/' + Number(animalId) + '/info'"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t("menuPages.animalInfo.info") }}
            </router-link>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="'/animal/' + Number(animalId) + '/weights'"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t("menuPages.animalInfo.tabMenu.weight") }}
            </router-link>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="'/animal/' + Number(animalId) + '/vaccines'"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t("menuPages.animalInfo.tabMenu.vaccination") }}
            </router-link>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="'/animal/' + Number(animalId) + '/diseases'"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t("menuPages.animalInfo.tabMenu.diseases") }}
            </router-link>
          </li>
          <!--end::Nav item-->
          <!--begin::Nav item-->
          <li class="nav-item">
            <router-link
              :to="'/animal/' + Number(animalId) + '/feeds'"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t("menuPages.animalInfo.tabMenu.feed") }}
            </router-link>
          </li>
          <!--end::Nav item-->

          <!--
              <li class="nav-item">
                <router-link
                  to="/animalsVaccine"
                  class="nav-link text-active-primary me-6"
                  active-class="active"
                >
                  {{ $t("menuPages.animalInfo.tabMenu.vaccination") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link text-active-primary me-6"
                  to="/feed"
                  active-class="active"
                >
                  {{ $t("menuPages.animalInfo.tabMenu.feed") }}
                </router-link>
              </li>
                            -->
        </ul>
      </div>

      <div class="card-header border-0 pt-5" style="padding-left: 0">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">{{
            $t("menuPages.animalWeight.pageName")
          }}</span>
        </h3>

        <!--begin::Toolbar-->
        <div class="card-toolbar" data-kt-buttons="true">
          <a
            class="
              btn btn-sm btn-color-muted btn-active btn-active-primary
              px-4
              me-1
            "
            :class="{ active: isChartVisible }"
            @click="isChartVisible = true"
            >{{ $t("menuPages.animalWeight.graphic") }}</a
          >

          <a
            class="
              btn btn-sm btn-color-muted btn-active btn-active-primary
              px-4
              me-1
            "
            :class="{ active: !isChartVisible }"
            @click="isChartVisible = false"
            >{{ $t("menuPages.animalWeight.list") }}</a
          >
        </div>
        <!--end::Toolbar-->
      </div>
      <div
        class="dataTables_wrapper dt-bootstrap4 no-footer"
        v-show="!isChartVisible"
      >
        <div
          v-if="isLoading"
          style="
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div class="text-center px-5 pb-7">
            <img
              alt="Logo"
              class="max-h-35px"
              src="/media/loading_icon.gif"
              style="width: 40px"
            />
          </div>
        </div>
        <div class="table-responsive" v-else-if="animalWeightList.length > 0">
          <table
            class="
              table
              align-middle
              table-row-dashed
              fs-6
              gy-5
              dataTable
              no-footer
            "
            id="kt_customers_table"
            role="grid"
          >
            <!--begin::Table-->
            <thead>
              <tr
                class="
                  text-start text-gray-400
                  fw-bolder
                  fs-7
                  text-uppercase
                  gs-0
                "
                role="row"
              >
                <td>#</td>
                <td>{{ $t("menuPages.myWeight.date") }}</td>
                <td>{{ $t("menuPages.myWeight.liveWeight") }}</td>
                <td>{{ $t("menuPages.animalInfo.description") }}</td>
                <!--<td>{{ $t("common.table.process") }}</td>-->
              </tr>
            </thead>

            <tbody class="fw-bold text-gray-600">
              <tr
                class="odd"
                v-for="(animalWeight, index) in animalWeightList"
                :key="animalWeight.weight.id"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  {{
                    new Date(animalWeight.weight.date).toLocaleDateString(
                      "tr-TR"
                    )
                  }}
                </td>
                <td>{{ animalWeight.weight.weight }}</td>
                <td>{{ animalWeight.weight.description }}</td>
                <!--
                  <td>
                    <a
                      href="#"
                      class="
                        btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                      "
                    >
                      <span class="svg-icon svg-icon-3">
                        <inline-svg
                          src="/media/icons/duotune/general/gen027.svg"
                        />
                      </span>
                    </a>
                  </td>-->
              </tr>
            </tbody>
            <!--end::Table-->
          </table>
        </div>
        <div v-else class="card-px text-center">
          <div class="text-center px-5 pb-7">
            <img
              src="/media/animal/animal.jpg"
              class="symbol symbol-100px symbol-lg-160px mw-100 mh-300px"
            />
          </div>
          <!--begin::Description-->
          <p class="text-gray-400 fs-5 fw-bold">
            <span>{{ $t("menuPages.myWeight.zeroCount") }}</span>
          </p>
          <!--end::Description-->
        </div>
      </div>

      <!--begin::Pagination-->
      <div
        class="d-flex flex-stack flex-wrap pt-10"
        v-if="animalWeightList.length != 0 && !isChartVisible"
      >
        <!--begin::Controls-->
        <div class="d-flex flex-wrap my-1">
          <!--begin::Select wrapper-->
          <div class="m-0">
            <!--begin::Select-->
            <select
              name="status"
              data-control="select2"
              data-hide-search="true"
              class="
                form-select form-select-white form-select-sm
                fw-bolder
                w-125px
              "
              v-model="pageSize"
              @change="getAnimalWeightList(1)"
            >
              <option value="5">5</option>
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
            <span>{{ $t("common.table.pagination.desc2") }}</span>

            <!--end::Select-->
          </div>
          <!--end::Select wrapper-->
        </div>
        <!--end::Controls-->

        <div class="fs-6 fw-bold text-gray-700">
          {{ totalItems }} {{ $t("common.table.pagination.desc") }}
          {{ (currentPage - 1) * pageSize + 1 }} -
          {{ Math.min(currentPage * pageSize, totalItems) }}
          {{ $t("common.table.pagination.desc1") }}
        </div>

        <!--begin::Pages-->
        <ul class="pagination">
          <li
            class="page-item previous"
            @click="getAnimalWeightList(currentPage - 1)"
            :class="{ disabled: currentPage === 1 }"
          >
            <a href="#" class="page-link"><i class="previous"></i></a>
          </li>

          <li
            v-for="page in pageCount"
            :key="page"
            class="page-item"
            :class="{ active: currentPage === page }"
          >
            <a href="#" class="page-link" @click="getAnimalWeightList(page)">{{
              page
            }}</a>
          </li>

          <li
            class="page-item next"
            @click="getAnimalWeightList(currentPage + 1)"
            :class="{ disabled: currentPage === pageCount }"
          >
            <a href="#" class="page-link"><i class="next"></i></a>
          </li>
        </ul>
        <!--end::Pages-->
      </div>
      <!--end::Pagination-->

      <div v-show="isChartVisible">
        <div
          v-if="isLoading"
          style="
            height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div class="text-center px-5 pb-7">
            <img
              alt="Logo"
              class="max-h-35px"
              src="/media/loading_icon.gif"
              style="width: 40px"
            />
          </div>
        </div>
        <div v-else>
          <apexchart
            type="area"
            :options="options[0]"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
  <Vaccinate></Vaccinate>
  <AddWeight></AddWeight>
  <AddDisease></AddDisease>
  <Feed></Feed>
  <AnimalTransfer></AnimalTransfer>
</template>

<script lang="ts">
import { Ref, defineComponent, onMounted, ref, watch } from "vue";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import AddDisease from "@/presentation/components/AddDisease.vue";
import Feed from "@/presentation/components/Feed.vue";
import AnimalTransfer from "@/presentation/components/AnimalTransfer.vue";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useRoute } from "vue-router";
import { AnimalWeightListModel } from "@/domain/animal/animalWeight/animalWeightList/model/AnimalWeightListModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { getCSSVariableValue } from "@/presentation/assets/ts/_utils";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  components: { Vaccinate, AddWeight, AddDisease, Feed, AnimalTransfer },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const swalNotification = new SwalNotification();
    const animalWeightList = ref<AnimalWeightListModel[]>([]);
    const animalController = store.state.ControllersModule.animalController;
    const animalId = route.params["id"];
    const isLoading = ref<boolean>(false);
    const isChartVisible = ref(true);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");

    const baseColor = getCSSVariableValue("--bs-success");
    const baseLightColor = getCSSVariableValue("--bs-light-success");
    const secondaryColor = getCSSVariableValue("--bs-warning");
    const secondaryLightColor = getCSSVariableValue("--bs-light-warning");

    const options = ref<any>([
      {
        chart: {
          fontFamily: "inherit",
          type: "area",
          height: 60,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {},
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "solid",
          opacity: 1,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: ["Başlangıç"],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
          crosshairs: {
            position: "front",
            stroke: {
              color: labelColor,
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (val) {
              return +val + " kg";
            },
          },
        },
        colors: [baseColor, secondaryColor],
        grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          colors: [baseLightColor, secondaryLightColor],
          strokeColor: [baseLightColor, secondaryLightColor],
          strokeWidth: 3,
        },
      },
    ]);

    const series = ref([
      {
        name: t("menuPages.myWeight.weightResult"),
        data: [0],
      },
    ]);

    watch(
      () => store.getters.newWeight,
      () => {
        if (store.getters.newWeight) getAnimalWeightList(1);
      }
    );

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_INFO));
      getAnimalWeightList(1);
    });

    const getAnimalWeightList = (page) => {
      isLoading.value = true;

      animalWeightList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalWeightListModel: AnimalWeightListModel = {
        weight: {
          animalId: Number(animalId),
          date: new Date(),
        },
        page: page,
        pageSize: pageSize.value,
      };

      animalController
        .animalWeightList(animalWeightListModel)
        .then((response) => {
          if (response.isSuccess) {
            series.value[0].data = [];
            response.getValue().forEach((animalWeight) => {
              totalItems.value = animalWeight.pagination.total;
              currentPage.value = animalWeight.pagination.page;
              pageCount.value = animalWeight.pagination.pageCount;
              animalWeightList.value.push(animalWeight);

              series.value[0].data.push(Number(animalWeight.weight.weight));

              const newDate = new Date(
                animalWeight.weight.date
              ).toLocaleDateString("tr-TR");
              options.value[0].xaxis.categories.push(newDate);
            });
            options.value[0].xaxis.categories.reverse();
            series.value[0].data.reverse();
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    return {
      isChartVisible,
      options,
      series,
      PAGE_TITLE_PATH,
      pageSize,
      currentPage,
      totalItems,
      pageCount,
      animalId,
      animalWeightList,
      getAnimalWeightList,
      isLoading,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
