
import { Ref, defineComponent, onMounted, ref, watch } from "vue";
import Vaccinate from "@/presentation/components/Vaccinate.vue";
import AddWeight from "@/presentation/components/AddWeight.vue";
import AddDisease from "@/presentation/components/AddDisease.vue";
import Feed from "@/presentation/components/Feed.vue";
import AnimalTransfer from "@/presentation/components/AnimalTransfer.vue";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useRoute } from "vue-router";
import { AnimalWeightListModel } from "@/domain/animal/animalWeight/animalWeightList/model/AnimalWeightListModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { getCSSVariableValue } from "@/presentation/assets/ts/_utils";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  components: { Vaccinate, AddWeight, AddDisease, Feed, AnimalTransfer },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const swalNotification = new SwalNotification();
    const animalWeightList = ref<AnimalWeightListModel[]>([]);
    const animalController = store.state.ControllersModule.animalController;
    const animalId = route.params["id"];
    const isLoading = ref<boolean>(false);
    const isChartVisible = ref(true);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");

    const baseColor = getCSSVariableValue("--bs-success");
    const baseLightColor = getCSSVariableValue("--bs-light-success");
    const secondaryColor = getCSSVariableValue("--bs-warning");
    const secondaryLightColor = getCSSVariableValue("--bs-light-warning");

    const options = ref<any>([
      {
        chart: {
          fontFamily: "inherit",
          type: "area",
          height: 60,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {},
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "solid",
          opacity: 1,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: ["Başlangıç"],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
          crosshairs: {
            position: "front",
            stroke: {
              color: labelColor,
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px",
            },
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0,
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
          },
          y: {
            formatter: function (val) {
              return +val + " kg";
            },
          },
        },
        colors: [baseColor, secondaryColor],
        grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          colors: [baseLightColor, secondaryLightColor],
          strokeColor: [baseLightColor, secondaryLightColor],
          strokeWidth: 3,
        },
      },
    ]);

    const series = ref([
      {
        name: t("menuPages.myWeight.weightResult"),
        data: [0],
      },
    ]);

    watch(
      () => store.getters.newWeight,
      () => {
        if (store.getters.newWeight) getAnimalWeightList(1);
      }
    );

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_INFO));
      getAnimalWeightList(1);
    });

    const getAnimalWeightList = (page) => {
      isLoading.value = true;

      animalWeightList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalWeightListModel: AnimalWeightListModel = {
        weight: {
          animalId: Number(animalId),
          date: new Date(),
        },
        page: page,
        pageSize: pageSize.value,
      };

      animalController
        .animalWeightList(animalWeightListModel)
        .then((response) => {
          if (response.isSuccess) {
            series.value[0].data = [];
            response.getValue().forEach((animalWeight) => {
              totalItems.value = animalWeight.pagination.total;
              currentPage.value = animalWeight.pagination.page;
              pageCount.value = animalWeight.pagination.pageCount;
              animalWeightList.value.push(animalWeight);

              series.value[0].data.push(Number(animalWeight.weight.weight));

              const newDate = new Date(
                animalWeight.weight.date
              ).toLocaleDateString("tr-TR");
              options.value[0].xaxis.categories.push(newDate);
            });
            options.value[0].xaxis.categories.reverse();
            series.value[0].data.reverse();
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    return {
      isChartVisible,
      options,
      series,
      PAGE_TITLE_PATH,
      pageSize,
      currentPage,
      totalItems,
      pageCount,
      animalId,
      animalWeightList,
      getAnimalWeightList,
      isLoading,
    };
  },
});
